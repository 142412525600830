import { Alert, Snackbar } from '@mui/material';
import React, { memo, useEffect, useState } from 'react'

function Notification(props) {

   

  return (
     <Snackbar
    open={props.showNotification}
    autoHideDuration={6000}
    sx={{ width: "50%",color:"white" }}
    onClose={() => {
      props.setShowNotification(false);
    }}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
  >
    <Alert
      onClose={() => {
        props.setShowNotification(false);
      }}
      severity={props.severity}
      variant="filled"
      sx={{ width: "100%",color:"white" }}
      
    >
      {props.notificationMsg}
    </Alert>
  </Snackbar> 
  )
}

export default memo(Notification);
