import {
  // Autocomplete,
  Box,
  Button,
  Chip,
  // Checkbox,
  CircularProgress,
  FormControl,
  // FormControlLabel,
  // FormGroup,
  // FormHelperText,
  Grid,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemText,
  Paper,
  // Stack,
  // Slider,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useEffect, useMemo, useRef, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";

import brandImage from "../assets/images/knowdis-brand.png";
// import { marks } from "../components/HelperFunctions";
// import Imagesdata from "../components/ImageData/Imagesdata";
import { SERVER_URL } from "../config";
import axios from "axios";
import Notification from "../components/notification/Notification";
import "../assets/css/loader.css";
import Statistics from "../components/statistics/Statistics";
import LazyImageComp from "../components/common/LazyImageComp";
import { FixedSizeList } from "react-window";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import "../assets/css/home.css";
import Papa from "papaparse";

// Render each row in the dropdown using react-window
const renderRow = ({ index, style, data }) => {
  const { filteredOptions, handleSelect } = data;
  return (
    <ListItem
      button
      style={style}
      key={index}
      onClick={() => handleSelect(filteredOptions[index])}
    >
      <ListItemText primary={filteredOptions[index]} />
    </ListItem>
  );
};

function Home() {
  const [mcat, setMcat] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null); // To store the selected option
  const [showDropdown, setShowDropdown] = useState(false); // To control dropdown visibility

  // const [quantileThreshold, setQuantileThreshold] = useState(0.2);
  // const [isChecked, setIsChecked] = useState(true);
  const [isloaded, setIsloaded] = useState(false);
  const [isloading, setisloading] = useState(false);

  const [anomaliesList, setAnomaliesList] = useState("");
  const [sellerTypes, setsellerTypes] = useState(null);

  const [isAccordionLoaded, setIsAccordionLoaded] = useState(false);
  //   const [streaming, setStreaming] = useState(false);
  const [serverImagesData, setServerImagesData] = useState([]); // this will hold the current rendered result
  const [sellerTypesFilteredData, setSellerTypesFilteredData] = useState([]); // this will hold all the result when seller types added

  const [serverImagesDataForFilter, setServerImagesDataForFilter] = useState(
    []
  ); // this will hold first result on submit , we can use this first result anytime.

  const [chipsLabels, setChipsLabels] = useState({
    free: false,
    free_verified: false,
    paid: false,
    premium: false,
    unknown: false,
    isPdpUrl: false,
  });

  const [mcatList, setMcatList] = useState([]);
  const [rows, setRows] = useState([]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [isFetchingTableData, setIsFetchingTableData] = useState(false);
  const [visibleCounts, setVisibleCounts] = useState({}); // Manage visible counts per accordion

  const eventSourceRef = useRef(null);

  const [tabvalue, setTabValue] = useState("1");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // notification inputs
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState("");
  const [severity, setSeverity] = useState("");

  // Filtered options based on user input
  const filteredOptions = useMemo(
    () =>
      mcatList.filter((option) =>
        option.toLowerCase().includes((mcat || "").toLowerCase())
      ),
    [mcat, mcatList]
  );

  // Handle option selection
  const handleSelect = (option) => {
    setSelectedOption(option); // Set the selected option in the input
    setMcat(option); // Set the search string as the selected option
    setShowDropdown(false); // Hide the dropdown
  };

  // Dynamically calculate the height for the FixedSizeList
  const getListHeight = () => {
    const optionCount = filteredOptions.length;
    const rowHeight = 46; // Height of each row
    const maxVisibleRows = 6; // Max number of visible rows
    const maxHeight = rowHeight * maxVisibleRows; // Max height for the dropdown

    return Math.min(optionCount * rowHeight, maxHeight); // Choose the smaller of calculated height or maxHeight
  };

  // const valuetext = (value) => {
  //   setQuantileThreshold(value);
  //   return `${value}`;
  // };

  // fetching streaming response
  //   useEffect(() => {
  //     //   let eventSource;

  //     console.log("streaming", eventSourceRef.current);

  //     if (streaming) {
  //       const eventSource = new EventSource(
  //         `${SERVER_URL}/run_pipeline/?mcat=${mcat}&quantile=${quantileThreshold}&show_remaining_images=${isChecked}`
  //       );
  //       eventSourceRef.current = eventSource;
  //       eventSource.onmessage = function (event) {
  //         const newData = JSON.parse(event.data);
  //         if (newData.complete) {
  //           // Stop streaming and close the event source
  //           eventSource.close();
  //           setStreaming(false); // Optionally update state to reflect no longer streaming
  //         } else {
  //           if (isAccordionLoaded) {
  //             setIsAccordionLoaded(false);
  //             // setStreaming(false);
  //           }
  //           setServerImagesData((prevData) => {
  //             const resultCategory = newData.result;
  //             const existingImages = prevData[resultCategory] || [];
  //             return {
  //               ...prevData,
  //               [resultCategory]: [...existingImages, newData],
  //             };
  //           });
  //         }
  //       };

  //       eventSource.onerror = function (event) {
  //         setShowNotification(true);
  //         setNotificationMsg("Something went wrong with streaming response");
  //         setSeverity("error");
  //         eventSource.close();
  //         setStreaming(false);
  //       };
  //     }

  //     return () => {
  //       // Cleanup function to close the EventSource when the component unmounts or dependencies change
  //       if (eventSourceRef.current) {
  //         eventSourceRef.current.close();
  //       }
  //     };
  //   }, [streaming]);

  // Update visible count for specific result category
  const increaseVisibleCount = () => {
    setVisibleCounts((prev) => ({
      ...prev,
      scrollCount: (prev["scrollCount"] || 7) + 7,
    }));
  };

  // fetching all the mcats
  const fetchList = async () => {
    try {
      setIsloaded(true);
      const response = await axios.get(`${SERVER_URL}/mcats_list/`);
      setMcatList(response.data);
      setIsloaded(false);
    } catch (error) {
      setShowNotification(true);
      setNotificationMsg("Facing issue while fetching MCATs");
      setSeverity("error");
      setIsloaded(false);
    }
  };

  // fetching mcats on first load
  useEffect(() => {
    fetchList();

    return () => {
      setMcatList([]);
      setServerImagesData([]);
      setServerImagesDataForFilter([]);
    };
  }, []);

  // handler to execute streaming response
  const handleStartStreaming = () => {
    if (!mcat) {
      setShowNotification(true);
      setNotificationMsg("Please provide all the inputs");
      setSeverity("error");
      return;
    }

    // setIsAccordionLoaded(true);

    // Close existing stream if it's currently active
    setChipsLabels({
      free: false,
      free_verified: false,
      paid: false,
      premium: false,
      unknown: false,
      isPdpUrl:false
    });

    setisloading(true);

    if (eventSourceRef.current) {
      setServerImagesData([]);
      setSellerTypesFilteredData([]);
      setServerImagesDataForFilter([]);
      eventSourceRef.current.close();
    }
    const encodedQuery = encodeURIComponent(mcat);
    const eventSource = new EventSource(
      `${SERVER_URL}/run_pipeline/?mcat=${encodedQuery}`
    );
    eventSourceRef.current = eventSource;
    eventSource.onmessage = function (event) {
      const newData = JSON.parse(event.data);

      if (newData.complete) {
        // Stop streaming and close the event source
        eventSource.close();
        setsellerTypes(newData);

        setAnomaliesList(
          `${newData.count} / ${newData.total} (${newData.percent})`
        );

        setisloading(false);

        //   setStreaming(false); // Optionally update state to reflect no longer streaming
      } else {
        if (isAccordionLoaded) {
          setIsAccordionLoaded(false);
          // setStreaming(false);
        }
        setServerImagesData((prevData) =>
          Array.isArray(prevData) ? [...prevData, newData] : [newData]
        );

        setSellerTypesFilteredData((prevData) =>
          Array.isArray(prevData) ? [...prevData, newData] : [newData]
        );

        setServerImagesDataForFilter((prevData) =>
          Array.isArray(prevData) ? [...prevData, newData] : [newData]
        );
      }
    };

    eventSource.onerror = function (event) {
      setShowNotification(true);
      setNotificationMsg("Something went wrong with streaming response");
      setSeverity("error");
      setisloading(false);
      eventSource.close();
      // setStreaming(false);
    };

    // setServerImagesData({});
    // setStreaming(true);
  };

  // filter chips handler 
  const chipsHandler = (label, labelValue) => {
    if (!sellerTypes) {
      return;
    }

    let allLabels = [];
    let totalCount = 0;
    let total = 0;

    let percentageIs = 0;

    for (let key in chipsLabels) {
      // collecting already selected seller types only
      if (chipsLabels[key] && key !== label && key !== "isPdpUrl") {
        allLabels.push(key);
      }
    }

    // now collecting current selected seller types if it was false earlier
    if (label !== "isPdpUrl" && !chipsLabels[label]) {
      allLabels.push(label);
    }

    let filterdData = [];

    if (allLabels.length === 0) {
      // if seller labels is empty

      // if additional filter applied
      // condition 1. when isPdpUrl clicked and its required value (labelValue) is true
      // condition 2. when isPdpUrl is not clicked but isPdpUrl was already true
      if (
        (label === "isPdpUrl" && labelValue === true) ||
        (label !== "isPdpUrl" && chipsLabels["isPdpUrl"])
      ) {
        // getting filter data
        filterdData = serverImagesDataForFilter.filter((item) => {
          if (item.pdp_url) {
            return true;
          }

          return false;
        });

        // default taking all labels to calculate totalcount and total values
        allLabels = ["free", "free_verified", "paid", "premium", "unknown"];
        totalCount = 0;
        total = 0;
        for (let i = 0; i < allLabels.length; i++) {
          // collecting only true values
          const keyTrue = `${allLabels[i]}__true`;
          totalCount +=
            sellerTypes.additional_stats.seller_type__has_pdp[keyTrue].count;
          total +=
            sellerTypes.additional_stats.seller_type__has_pdp[keyTrue].total;
        }

        setSellerTypesFilteredData(filterdData);
        setServerImagesData(filterdData);
      } else {
        total = sellerTypes.total;
        totalCount = sellerTypes.count;

        setSellerTypesFilteredData(serverImagesDataForFilter);
        setServerImagesData(serverImagesDataForFilter);
      }
    } else {
      // if some of the seller labels selected
      // if additional filter applied

      // condition 1. when isPdpUrl clicked and its required value (labelValue) is true
      // condition 2. when isPdpUrl is not clicked but isPdpUrl was already true
      if (
        (label === "isPdpUrl" && labelValue === true) ||
        (label !== "isPdpUrl" && chipsLabels["isPdpUrl"])
      ) {
        filterdData = serverImagesDataForFilter.filter((item) => {
          if (allLabels.includes(item.seller_type) && item.pdp_url) {
            return true;
          }

          return false;
        });
        totalCount = 0;
        total = 0;
        // now calculate total and totalcount again
        for (let i = 0; i < allLabels.length; i++) {
          // collecting only true values
          const keyTrue = `${allLabels[i]}__true`;
          totalCount +=
            sellerTypes.additional_stats.seller_type__has_pdp[keyTrue].count;
          total +=
            sellerTypes.additional_stats.seller_type__has_pdp[keyTrue].total;
        }
      } else {
        // if additional filter (isPdpUrl) is not applied only selcted seller types labels
        filterdData = serverImagesDataForFilter.filter((item) => {
          if (allLabels.includes(item.seller_type)) {
            return true;
          }
          return false;
        });

        totalCount = 0;
        total = 0;
        // calculating total and totalcount
        for (let i = 0; i < allLabels.length; i++) {
          // calculation based on submitted seller types.
          const keyFalse = `${allLabels[i]}__false`;
          const keyTrue = `${allLabels[i]}__true`;
          totalCount +=
            sellerTypes.additional_stats.seller_type__has_pdp[keyFalse].count +
            sellerTypes.additional_stats.seller_type__has_pdp[keyTrue].count;
          total +=
            sellerTypes.additional_stats.seller_type__has_pdp[keyFalse].total +
            sellerTypes.additional_stats.seller_type__has_pdp[keyTrue].total;
        }
      }

      // console.log("filterdata", filterdData);
      setSellerTypesFilteredData(filterdData);
      setServerImagesData(filterdData);
    }

    // now reinitializing values of anmoaliesList and all the chips
    if (total !== 0) percentageIs = (totalCount / total) * 100;

    setAnomaliesList(`${totalCount} / ${total} (${percentageIs.toFixed(2)}%)`);
    setChipsLabels((prevLabels) => ({
      ...prevLabels,
      [label]: !prevLabels[label], // Toggle the specific label's state
    }));
  };


  // download result as csv
  const downloadResult = () => {
    // Convert JSON to CSV using PapaParse

    let selectedMcatName = serverImagesData[0].mcat;

    if (!selectedMcatName) {
      selectedMcatName = "";
    }

    const csvData = serverImagesData.map((item, index) => {
      return {
        ID: index + 1,
        Mcat: item.mcat,
        Display_ID: item.display_id,
        Image_Url: item.image_url,
        Seller_Type: item.seller_type,
        Product_Page: item.pdp_url,
      };
    });

    const csv = Papa.unparse(csvData);

    // Create a Blob from the CSV
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `PhotoX_${selectedMcatName}_Result.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Notification
        showNotification={showNotification}
        setShowNotification={setShowNotification}
        notificationMsg={notificationMsg}
        severity={severity}
      />
      <Box
        sx={{
          mx: isMobile ? 0 : 15,
          mt: 5,
        }}
      >
        <Box textAlign={"right"}>
          <img src={brandImage} width={150} alt="Knowdis Brand" />
        </Box>
        <Box textAlign={"center"} mb={2}>
          <Box>
            <Typography className="app-title" variant="h2">
              Photo X
            </Typography>
          </Box>
        </Box>

        <TabContext value={tabvalue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Photo X" value="1" />
              <Tab label="Statistics" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Paper elevation={4}>
              <Box sx={{ padding: 3 }}>
                <Box m={1}>
                  <Typography
                    fontWeight={"bold"}
                    display="inline-flex"
                    alignItems="center"
                  >
                    Select MCAT (
                    {isloaded ? (
                      <div className="loader"></div>
                    ) : (
                      `Total: ${mcatList.length}`
                    )}
                    )
                  </Typography>
                </Box>
                <Box
                  m={1}
                  display={"flex"}
                  justifyContent={"center"}
                  gap={"5px"}
                >
                  <FormControl sx={{ minWidth: 120, width: "100%", mb: 2 }}>
                    <TextField
                      fullWidth
                      size="small"
                      autoComplete="off"
                      // label="Search MCAT"
                      placeholder="Search MCAT"
                      variant="outlined"
                      value={mcat} // Display the selected option or search string
                      onChange={(e) => {
                        setMcat(e.target.value);
                        setShowDropdown(true); // Show the dropdown when typing
                      }} // Handle input changes
                      onClick={() => setShowDropdown(true)} // Show dropdown on click
                      onBlur={() => setShowDropdown(false)} // Hide dropdown on blur
                    />

                    {/* Dropdown for filtered options */}
                    {showDropdown && filteredOptions.length > 0 && (
                      <Paper
                        onMouseDown={(e) => e.preventDefault()} // Prevent dropdown from closing on item click
                        style={{
                          position: "absolute",
                          top: "45px",
                          zIndex: 1,
                          maxHeight: "300px",
                          overflow: "hidden",
                          width: "100%",
                        }}
                      >
                        <FixedSizeList
                          height={getListHeight()} // Dynamically set height
                          itemSize={46} // Height of each row in the dropdown
                          itemCount={filteredOptions.length} // Number of filtered options
                          itemData={{ filteredOptions, handleSelect }} // Pass options and handler to the list
                          // width={300}
                        >
                          {renderRow}
                        </FixedSizeList>
                      </Paper>
                    )}
                    {/* autocomplete list */}
                    {isloaded && <LinearProgress color="secondary" />}
                    {/* <FormHelperText
                      fontWeight="bold"
                      sx={{ fontSize: "0.9rem" }}
                    >
                      List updates every minute. Reload to see the latest MCATs.
                    </FormHelperText> */}
                  </FormControl>

                  {/* loader button */}
                  <IconButton
                    aria-label="Example"
                    // onClick={handleReplay}
                    onClick={fetchList}
                    sx={{
                      border: "1px solid grey",
                      borderRadius: "4px",
                      height: "40px",
                      // marginBottom: "8px",
                      // padding: "4px",
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Box>

                {/* slider */}
                {/* <Box m={1}>
                  <Typography fontWeight={"bold"}>
                    Quantile Threshold
                  </Typography>
                  <Slider
                    aria-label="Always visible"
                    defaultValue={0.2}
                    getAriaValueText={valuetext}
                    step={0.05}
                    marks={marks}
                    min={0.05}
                    max={1.0}
                    valueLabelDisplay="on"
                    color="success"
                    size="medium"
                  />
                </Box> */}

                {/* <Box m={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={(e) => setIsChecked(e.target.checked)}
                        />
                      }
                      label="Show Images above the Quantile"
                    />
                  </FormGroup>
                </Box> */}

                <Box m={1}>
                  <Button
                    variant="outlined"
                    // sx={{ mt: 2 }}
                    onClick={handleStartStreaming}
                    disabled={isAccordionLoaded}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
            {isloading && <LinearProgress color="secondary" />}

            {/* old accordion code */}
            {/* {serverImagesData && (
              <Imagesdata
                serverImagesData={serverImagesData}
                isAccordionLoaded={isAccordionLoaded}
              />
            )} */}

            {/* serverImagesData.length > 0 */}
            {serverImagesDataForFilter.length > 0 && (
              <>
                <Box className="filterOptions">
                  {/* chips sets for filter based on seller types */}

                  <Box>
                    <Typography fontWeight={"bold"}>Seller Types: </Typography>
                    <Chip
                      label="free"
                      // variant={chipsLabels.free ? "filled" : "outlined"}
                      // color={chipsLabels.free ? "warning" : "info"}
                      variant="filled"
                      sx={{
                        fontSize: "1rem",
                        p: 1,
                        m: 0.5,
                        background: chipsLabels.free ? "#582FF1" : "none",
                        color: chipsLabels.free ? "white" : "#582FF1",
                        border: "1px solid #582FF1",
                        "&:hover": {
                          background: "#582FF1",
                          color: "white",
                        },
                      }}
                      onClick={() => chipsHandler("free", !chipsLabels["free"])}
                    />
                    <Chip
                      label="free_verified"
                      className="chipsStyle"
                      sx={{
                        background: chipsLabels.free_verified
                          ? "#582FF1"
                          : "none",
                        color: chipsLabels.free_verified ? "white" : "#582FF1",
                      }}
                      onClick={() =>
                        chipsHandler(
                          "free_verified",
                          !chipsLabels["free_verified"]
                        )
                      }
                    />
                    <Chip
                      label="paid"
                      className="chipsStyle"
                      sx={{
                        background: chipsLabels.paid ? "#582FF1" : "none",
                        color: chipsLabels.paid ? "white" : "#582FF1",
                      }}
                      onClick={() => chipsHandler("paid", !chipsLabels["paid"])}
                    />
                    <Chip
                      label="premium"
                      className="chipsStyle"
                      sx={{
                        background: chipsLabels.premium ? "#582FF1" : "none",
                        color: chipsLabels.premium ? "white" : "#582FF1",
                      }}
                      onClick={() =>
                        chipsHandler("premium", !chipsLabels["premium"])
                      }
                    />
                    <Chip
                      label="unknown"
                      className="chipsStyle"
                      sx={{
                        background: chipsLabels.unknown ? "#582FF1" : "none",
                        color: chipsLabels.unknown ? "white" : "#582FF1",
                      }}
                      onClick={() =>
                        chipsHandler("unknown", !chipsLabels["unknown"])
                      }
                    />
                  </Box>

                  {/* calculated values from backend */}
                  <Box className="found-filter">
                    <Typography
                      fontWeight={"bold"}
                      display="inline-flex"
                      alignItems="center"
                      variant="h6"
                    >
                      Found&nbsp;
                      {isloading ? (
                        <div className="loader"> </div>
                      ) : (
                        `${anomaliesList}`
                      )}
                      &nbsp;anomalies
                    </Typography>
                  </Box>
                </Box>

                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography fontWeight={"bold"}>
                      Additional Filter:{" "}
                    </Typography>
                    <Box>
                      <Chip
                        label="Product Page Exist"
                        // variant={chipsLabels.free ? "filled" : "outlined"}
                        // color={chipsLabels.free ? "warning" : "info"}
                        variant="filled"
                        sx={{
                          fontSize: "1rem",
                          p: 1,
                          m: 0.5,
                          mt: 1,
                          background: chipsLabels.isPdpUrl ? "#582FF1" : "none",
                          color: chipsLabels.isPdpUrl ? "white" : "#582FF1",
                          border: "1px solid #582FF1",
                          "&:hover": {
                            background: "#582FF1",
                            color: "white",
                          },
                        }}
                        onClick={() =>
                          chipsHandler("isPdpUrl", !chipsLabels["isPdpUrl"])
                        }
                      />
                    </Box>
                  </Box>
                  <Box textAlign={"right"}>
                    <Button
                      className="small-btns orange-shade"
                      variant="contained"
                      size="small"
                      startIcon={<FileDownloadIcon />}
                      onClick={downloadResult}
                    >
                      Export Result
                    </Button>
                  </Box>
                </Box>
              </>
            )}

            <Box
              textAlign={"center"}
              sx={{
                maxHeight: "40em", // Set the height to 40em
                overflow: "auto", // Allow scrolling within the AccordionDetails
                mt: 2,
                p: 1,
              }}
              onScroll={(e) => {
                const bottom =
                  e.target.scrollHeight - e.target.scrollTop ===
                  e.target.clientHeight;
                if (
                  bottom &&
                  serverImagesData.length > (visibleCounts["scrollCount"] || 7)
                ) {
                  increaseVisibleCount();
                }
              }}
            >
              <Grid container spacing={2}>
                {serverImagesData &&
                  serverImagesData
                    .slice(0, visibleCounts["scrollCount"] || 7)
                    .map((item, idx) => (
                      <Grid item xs={12} sm={6} md={2} key={idx}>
                        <LazyImageComp
                          src={item.image_url}
                          pdp_url={item.pdp_url}
                          alt={`${item.mcat}` + `${item.display_id}`}
                          display_id={item.display_id}
                          setShowNotification={setShowNotification}
                          setNotificationMsg={setNotificationMsg}
                          setSeverity={setSeverity}
                        />
                      </Grid>
                    ))}
                {serverImagesData.length >
                  (visibleCounts["scrollCount"] || 7) && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      height: 300,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={100} />
                  </Grid>
                )}
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Statistics
              rows={rows}
              setRows={setRows}
              isFetchingTableData={isFetchingTableData}
              setIsFetchingTableData={setIsFetchingTableData}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default Home;
